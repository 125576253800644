// @ts-nocheck
import { PlayerRatio } from '@netvision/lib-player'
import {
  ArchivePlayerConfig,
  IWidgetProps,
  IWidgetPropsProvider,
  LivePlayerConfig,
  MultiModePlayerConfig,
  OldArchivePlayerConfig,
  OldSavedPlayerConfig,
  PlayerEntityTypes,
  PlayerModes,
  PlayerPlayModes,
  PlayerViewModes,
  PlayerWidgetPropsWithOldConfig,
  SavedPlayerConfig
} from '../IWidgetProps'

const DEFAULT_LIB_API = {
  name: 'lib-api',
  mixins: ['cubejs']
}

export const isPlayerModeByProps = (
  props: PlayerWidgetPropsWithOldConfig,
  mode: PlayerModes
): props is MultiModePlayerConfig => {
  if (!props) return false
  if (!('playerMode' in props)) return false
  return props.playerMode === mode
}

export const isNewLiveWidgetProps = (
  props?: PlayerWidgetPropsWithOldConfig
): props is LivePlayerConfig => {
  if (!props) return false
  if (!('playerMode' in props)) return false
  return props.playerMode === PlayerModes.Live
}

const isOldSavedProps = (
  receivedProps?: PlayerWidgetPropsWithOldConfig
): receivedProps is OldSavedPlayerConfig => {
  if (!receivedProps) return false
  return 'record' in receivedProps && Boolean(receivedProps.record)
}

export const isNewSavedProps = (
  receivedProps?: PlayerWidgetPropsWithOldConfig
): receivedProps is SavedPlayerConfig => {
  if (!receivedProps) return false
  if (!('playerMode' in receivedProps)) return false
  return (
    'record' in receivedProps &&
    Boolean(receivedProps.record) &&
    receivedProps.playerMode === PlayerModes.Saved
  )
}

export const isNewArchiveProps = (
  receivedProps?: PlayerWidgetPropsWithOldConfig
): receivedProps is ArchivePlayerConfig => {
  if (!receivedProps) return false
  if (!('playerMode' in receivedProps)) return false
  return receivedProps.playerMode === PlayerModes.Archive
}

// TODO delete
// tmp for sync mode
// let wasMaster = ''
const isOldArchiveProps = (
  receivedProps?: PlayerWidgetPropsWithOldConfig
): receivedProps is OldArchivePlayerConfig => {
  if (!receivedProps) return false
  // TODO tmp solution for test syncArchive mode
  // @ts-ignore
  // return receivedProps?.camera?.id
  const isArchiveSaved =
    'entity' in receivedProps &&
    (receivedProps.entity?.type === 'File' || receivedProps.entity?.type === 'Record')
  const isArchiveCamera = 'eventCameraField' in receivedProps
  return isArchiveSaved || isArchiveCamera
}

export const normalizeProps = (data: IWidgetProps): IWidgetPropsProvider => {
  const { props: receivedProps, ...rest } = data

  if (!receivedProps) throw new Error('[normalizeProps]: Can not get received props to widget')

  if (isPlayerModeByProps(receivedProps, PlayerModes.MultiMode)) {
    const cameraId = receivedProps?.camera?.id
    return {
      ...rest,
      props: {
        eventBus: receivedProps.eventBus,
        ratio: receivedProps.ratio ? (receivedProps.ratio as PlayerRatio) : undefined,
        id: cameraId,
        camera: receivedProps.camera,
        blockOnMatch: receivedProps.blockOnMatch,
        playerMode: PlayerModes.MultiMode,
        coopPlayerFlag: receivedProps.coopPlayerFlag,
        initMode: receivedProps.initMode,
        editMode: receivedProps.editMode,
        autoplay: receivedProps.autoplay,
        viewMode: receivedProps.viewMode ? receivedProps.viewMode : PlayerViewModes.Small,
        width: receivedProps.width,
        height: receivedProps.height,
        // TODO подумать нужен ли этот пропс
        // lib: receivedProps?.api || receivedProps?.lib || DEFAULT_LIB_API,
        // playMode: receivedProps.previewOnly
        //   ? PlayerPlayModes.PreviewOnly
        //   : receivedProps.autoplay
        //   ? PlayerPlayModes.Autoplay
        //   : PlayerPlayModes.Default,
        // previewOptions: {
        //   previewUpdateInterval: receivedProps.previewInterval
        // },
        // refetch: {
        //   retryAfterTime: receivedProps.refreshAfterUnlockTime,
        //   maxRetryCount: receivedProps.maxStreamRefreshAttempts
        // },
        // overlayMountHooks: receivedProps?.overlayMountHooks,
        maxRecordDuration: receivedProps.maxRecordDuration,
        streamTypes: receivedProps.streamTypes,
        streamOptions: {
          // type: receivedProps.streamType || 'SLDP',
          defaultStreamType: receivedProps.defaultStreamType,
          defaultStreamId: receivedProps.defaultStreamId,
          switchToCurrentStreamByTypeOnFullScreen:
            receivedProps.switchToCurrentStreamByTypeOnFullScreen
        },
        // stalledOptions: {
        //   previewUpdateInterval: receivedProps.stalledPreviewInterval,
        //   timeout: receivedProps.stalledTimeout
        // },
        // // TODO пропсы требуются для работы архива
        entity: { id: cameraId, type: 'Camera' },
        playerOptions: {
          forwardAmount: receivedProps.forwardAmount,
          initialTime: receivedProps.initialTime,
          skipPreview: receivedProps.skipPreview
        },
        timeLineOptions: {
          initialOffset: receivedProps?.initialOffset,
          breakLimit: receivedProps?.breakLimit
        },
        features: {
          updatePresetImageOn: receivedProps.updatePresetImageOn,
          fullScreenOnDblclick: receivedProps.fullScreenOnDblclick,
          locking: !receivedProps.featureLockingDisabled,
          disableAutoSwitchToArchive: receivedProps.disableAutoSwitchToArchive,
          ptz: {
            mode:
              typeof receivedProps.ptzRequiresFullscreen === 'undefined' ||
              receivedProps.ptzRequiresFullscreen
                ? 'fullscreen'
                : 'default',
            disable: receivedProps.disablePtz,
            initiallyShow: receivedProps.initiallyShowPtz
          },
          timezoneSettings: {
            ...receivedProps.camera.timezoneSettings
          }
        },
        // TODO deprecated
        deprecatedStreamSetter: receivedProps.getStreamSetterByType,
        deprecatedPlayingGroupId: receivedProps.playingGroupId
      }
    } as IWidgetPropsProvider
  }

  if (isNewLiveWidgetProps(receivedProps)) {
    return {
      ...rest,
      props: {
        ...receivedProps,
        playerMode: PlayerModes.Live
      }
    } as IWidgetPropsProvider
  }

  if (isOldSavedProps(receivedProps) || isNewSavedProps(receivedProps)) {
    return {
      ...rest,
      props: {
        ...receivedProps,
        playerMode: PlayerModes.Saved
      }
    } as IWidgetPropsProvider
  }

  if (isNewArchiveProps(receivedProps)) {
    return {
      ...rest,
      props: {
        ...receivedProps,
        playerMode: PlayerModes.Archive
      }
    } as IWidgetPropsProvider
  }

  if (isOldArchiveProps(receivedProps)) {
    const cameraEntityId = receivedProps?.camera?.id || receivedProps?.cameraId

    // // todo delete
    // // tmp mock for sync mode
    // if (!wasMaster) {
    //   wasMaster = cameraEntityId || ''
    // }

    return {
      ...rest,
      props: {
        eventBus: receivedProps.eventBus,
        lib: receivedProps?.lib || DEFAULT_LIB_API,
        syncModeSettings: {
          enable: false
          // operatingMode: cameraEntityId === wasMaster ? 'master' : 'slave'
        },
        entity: cameraEntityId ? { id: cameraEntityId, type: 'Camera' } : receivedProps.entity,
        width: receivedProps.width,
        height: receivedProps.height,
        playerMode: PlayerModes.Archive,
        maxRecordDuration: receivedProps.maxRecordDuration,
        timeLineOptions: {
          initialOffset: receivedProps.initialOffset,
          breakLimit: receivedProps.breakLimit
        },
        playerOptions: {
          forwardAmount: receivedProps.forwardAmount,
          initialTime: receivedProps.initialTime,
          skipPreview: receivedProps.skipPreview
        },
        styles: {
          height: receivedProps.height,
          width: receivedProps.width
        },
        streamOptions: {
          type: receivedProps.streamType,
          defaultStreamType: '',
          defaultStreamId: '',
          switchToCurrentStreamByTypeOnFullScreen: undefined
        },
        eventsOptions: {
          eventEntityType: receivedProps.eventEntityType,
          eventCameraField: receivedProps.eventCameraField,
          eventExtraProps: receivedProps.eventExtraProps,
          showEvents: receivedProps.showEvents,
          useSingleEvent: receivedProps.entity,
          canvasStyles: receivedProps.canvasStyles
        },
        // TODO нормализовать пропсы
        id: cameraEntityId,
        features: {
          locking: receivedProps.features?.lockingEnabled,
          ptz: {
            mode: 'default',
            initiallyShow: false
          }
        }
      }
    } as IWidgetPropsProvider
  }
  // TODO разобраться с пропсами
  const cameraEntityId = receivedProps?.camera?.id || receivedProps?.cameraId
  return {
    ...rest,
    props: {
      ratio: receivedProps.ratio,
      id: receivedProps?.camera?.id || receivedProps?.cameraId,
      type: PlayerEntityTypes.Camera,
      playerMode: PlayerModes.Live,
      lib: receivedProps?.api || receivedProps?.lib || DEFAULT_LIB_API,
      playMode: receivedProps.previewOnly
        ? PlayerPlayModes.PreviewOnly
        : receivedProps.autoplay
        ? PlayerPlayModes.Autoplay
        : PlayerPlayModes.Default,
      previewOptions: {
        previewUpdateInterval: receivedProps.previewInterval
      },
      refetch: {
        retryAfterTime: receivedProps.refreshAfterUnlockTime,
        maxRetryCount: receivedProps.maxStreamRefreshAttempts
      },
      overlayMountHooks: receivedProps?.overlayMountHooks,
      streamOptions: {
        type: receivedProps.streamType || 'SLDP',
        defaultStreamType: receivedProps.defaultStreamType,
        defaultStreamId: receivedProps.defaultStreamId,
        switchToCurrentStreamByTypeOnFullScreen:
          receivedProps.switchToCurrentStreamByTypeOnFullScreen
      },
      stalledOptions: {
        previewUpdateInterval: receivedProps.stalledPreviewInterval,
        timeout: receivedProps.stalledTimeout
      },
      // TODO пропсы требуются для работы архива
      entity: cameraEntityId ? { id: cameraEntityId, type: 'Camera' } : receivedProps.entity,
      playerOptions: {
        forwardAmount: receivedProps.forwardAmount,
        initialTime: receivedProps.initialTime,
        skipPreview: receivedProps.skipPreview
      },
      timeLineOptions: {
        initialOffset: receivedProps?.initialOffset,
        breakLimit: receivedProps?.breakLimit
      },
      features: {
        updatePresetImageOn: receivedProps.updatePresetImageOn,
        fullScreenOnDblclick: receivedProps.fullScreenOnDblclick,
        locking: !receivedProps.featureLockingDisabled,
        ptz: {
          mode:
            typeof receivedProps.ptzRequiresFullscreen === 'undefined' ||
            receivedProps.ptzRequiresFullscreen
              ? 'fullscreen'
              : 'default',
          disable: receivedProps.disablePtz,
          initiallyShow: receivedProps.initiallyShowPtz
        }
      },
      // TODO deprecated
      deprecatedStreamSetter: receivedProps.getStreamSetterByType,
      deprecatedPlayingGroupId: receivedProps.playingGroupId
    }
  } as IWidgetPropsProvider
}
