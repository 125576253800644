import { useEffect } from 'react'

type useWatchResizePropsT = {
  root?: HTMLElement, selector?: string, onResizeEvent?: (entry: ResizeObserverEntry)=>void
}

export const useWatchResize = ({ root, selector, onResizeEvent }: useWatchResizePropsT) => {
  useEffect(() => {
    if (!root || !onResizeEvent) return
    const onResize: ResizeObserverCallback = ([entry]: ResizeObserverEntry[]) => {
      onResizeEvent && onResizeEvent(entry)
    }

    const resizeObserver = new ResizeObserver(onResize)
    const element = !selector ? root : root.querySelector(selector)

    if (!element) {
      console.error('[useWatchResize] Bad element or selector')
      return
    }
    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [root, selector, onResizeEvent])
}
