import { IEntity, TLibProp } from '@netvision/lib-api-repo'
import { PlayerRatio, StreamTypes } from '@netvision/lib-player'
import { WidgetReceivedProps } from '@netvision/lib-widget-renderer'
import { PlayerMode } from './constants'

export enum PlayerEntityTypes {
  Camera = 'Camera',
  Record = 'Record',
  File = 'File'
}

export enum PlayerPlayModes {
  Autoplay = 'Autoplay',
  PreviewOnly = 'PreviewOnly',
  Default = 'Default'
}

export enum PlayerModes {
  Live = 'Live',
  Archive = 'Archive',
  Saved = 'Saved',
  MultiMode = 'MultiMode'
}

export enum PlayerViewModes {
  Small = 'Small',
  Big = 'Big'
}

export enum RecordStatuses {
  NotStarted = 'NotStarted',
  Failed = 'Failed',
  Loaded = 'Loaded',
  InProgress = 'InProgress'
}

type BasePlayerOptions = {
  id: string
  defaultStreamType?: string
  defaultStreamId?: string
  refetch?: {
    retryAfterTime?: number
    maxRetryCount?: number
  }
  batchAttributes?: string[]
  type: keyof typeof PlayerEntityTypes
  ratio?: PlayerRatio | undefined
  playerMode: PlayerModes
  playMode: keyof typeof PlayerPlayModes
  lib: TLibProp
  className?: string
  previewOptions?: {
    previewUpdateInterval?: number | false
  }
  stalledOptions?: {
    timeout: number
    previewUpdateInterval: number | false
  }
  eventBus?: Record<string, any>
  disableBlur?: true
}

export interface LivePlayerConfig extends BasePlayerOptions {
  type: PlayerEntityTypes.Camera
  overlayMountHooks?: MountHooks<OverlayParams>
  streamOptions: {
    type: StreamTypes
    defaultStreamType?: string
    defaultStreamId?: string
    switchToCurrentStreamByTypeOnFullScreen?: string
  }
  features?: {
    updatePresetImageOn?: boolean
    fullScreenOnDblclick?: boolean
    locking?: boolean
    ptz: {
      mode: 'default' | 'fullscreen'
      disable?: boolean
      initiallyShow?: boolean
    }
  }
  deprecatedPlayingGroupId?: string
  deprecatedStreamSetter?: (setActiveStreamType: (streamType: string) => void) => void
}

export type OldLivePlayerProps = {
  camera?: {
    id: string
  }
  cameraId?: string
  api?: TLibProp
  lib?: TLibProp
  autoplay?: boolean
  ratio?: string | 'fit' | null
  previewOnly?: boolean
  previewInterval?: number
  stalledTimeout?: number
  stalledPreviewInterval?: number
  updatePresetImageOn?: boolean
  initiallyShowPtz?: boolean

  defaultStreamType?: string
  defaultStreamId?: string

  ptzRequiresFullscreen?: boolean | true
  disablePtz?: boolean | false
  overlayMountHooks?: MountHooks<OverlayParams>
  streamType?: string | 'SLDP'

  switchToCurrentStreamByTypeOnFullScreen: string
  fullScreenOnDblclick: boolean
  refreshAfterUnlockTime: number
  maxStreamRefreshAttempts: number
  featureLockingDisabled: boolean

  // deprecated properties
  playingGroupId?: string
  getStreamSetterByType?: (setActiveStreamType: (streamType: string) => void) => void
}

export interface SavedPlayerConfig
  extends Omit<
    BasePlayerOptions,
    'type' | 'batchAttributes' | 'defaultStreamType' | 'defaultStreamId' | 'id'
  > {
  record?: TVideo
  /**
   * Forward amount in seconds
   * default: 5
   */
  forwardAmount?: number
}

export type TVideo = ICameraRecord | IVideoFile

export interface ITimeline {
  duration?: number
  streamId?: string
  start: number
  end: number
}

export interface IRecord<T extends string> {
  type: T
  id: string
  duration: number
  snapshotUrl: string
  streamUrl: string
}

export interface ICameraRecord extends IRecord<'Record'> {
  from: number
  cameraId: string
  status?: RecordStatuses
  dvrTimelines?: ITimeline[]
}

export interface IVideoFile extends IRecord<'File'> {
  extension: string
  dateCreated: string
  owner: string
  parentFolderId: string
  resourceId: string
  size: number
}

export interface OldSavedPlayerConfig {
  /**
   * Record or record
   */
  record?: TVideo
  /**
   * className for root
   */
  className: string
  /**
   * player aspect ratio
   * fit - fit container
   * '${width}:{height}' - f.e 4:3
   */
  ratio: string
  /**
   * Forward amount in seconds
   * default: 5
   */
  forwardAmount?: number
  lib?: TLibProp
}

export interface MultiModePlayerConfig extends BasePlayerOptions {
  id: string
  camera: {
    address: object
    archiveId: string
    cameraType: string
    dvrSettings: { depth: number }
    iconClass: string
    id: string
    location: string
    originalStreamUrl: string
    originalSubStreamUrl: string
    servicePath: string
    title: string
    type: 'Camera'
    indexInLayout: number
    category: string
  }
  blockOnMatch: Record<string, string[]>
  // TODO флаг для запуска в совместном режиме
  coopPlayerFlag?: boolean
  editMode?: boolean
  initMode?: PlayerMode
  viewMode: PlayerViewModes
  autoplay?: boolean
  height?: number | string
  width?: number | string
  maxRecordDuration?: number
  streamTypes: Array<{
    title: string
    streamType: string
  }>
  // type: PlayerEntityTypes.Camera
  // overlayMountHooks?: MountHooks<OverlayParams>
  streamOptions: {
    // TODO обсудить потребность в streamOptions.type
    // type: StreamTypes
    defaultStreamType?: string
    defaultStreamId?: string
    switchToCurrentStreamByTypeOnFullScreen?: boolean
  }
  entity?: ArchiveEntities
  playerOptions: {
    forwardAmount: number
    initialTime?: number
    skipPreview: boolean | false
  }
  timeLineOptions: {
    initialOffset: number
    breakLimit?: number
  }
  features?: {
    updatePresetImageOn?: boolean
    fullScreenOnDblclick?: boolean
    locking?: boolean
    disableAutoSwitchToArchive?: boolean
    ptz: {
      mode: 'default' | 'fullscreen'
      disable?: boolean
      initiallyShow?: boolean
    }
    timezoneSettings?: {
      isActive: boolean
      timezone: string
    }
  }
  deprecatedPlayingGroupId?: string
  deprecatedStreamSetter?: (setActiveStreamType: (streamType: string) => void) => void
}

export interface ArchivePlayerConfig extends BasePlayerOptions {
  entity?: ArchiveEntities
  maxRecordDuration?: number
  height?: number | string
  width?: number | string
  syncModeSettings?: {
    enable: boolean
    operatingMode: 'master' | 'slave'
  }
  timeLineOptions: {
    initialOffset: number
    breakLimit?: number
  }
  playerOptions: {
    forwardAmount: number
    initialTime?: number
    skipPreview: boolean | false
  }
  eventsOptions: {
    eventEntityType: 'AssignmentEvent'
    eventCameraField: string
    eventExtraProps: IEventExtraPropDesc[]
    showEvents?: boolean | true
    useSingleEvent?: 'IEntity'
    canvasStyles?: 'ICanvasOverrideStyles'
  }
  styles: {
    height?: number | string
    width?: number | string
  }
  streamOptions: {
    type: 'standard' | 'tbo'
    defaultStreamType?: string
    defaultStreamId?: string
    switchToCurrentStreamByTypeOnFullScreen?: boolean
  }
  features: {
    locking?: boolean
    // TODO нормализовать пропсы
    ptz: {
      mode: 'default' | 'fullscreen'
      disable?: boolean
      initiallyShow?: boolean
    }
  }
}

export interface OldArchivePlayerConfig {
  cameraId?: string
  camera?: {
    id: string
  }
  entity?: ArchiveEntities
  initialTime?: number
  skipPreview: boolean | false
  eventBus?: Record<string, any>
  height?: number | string
  width?: number | string
  className?: string
  ratio?: string
  streamType?: 'standard' | 'tbo'
  eventEntityType: 'AssignmentEvent' | 'TboEvent'
  eventCameraField: string
  eventExtraProps: IEventExtraPropDesc[]
  showEvents?: boolean | true
  event?: IEntity
  overlayMountHooks?: MountHooks<OverlayParams>
  lang?: string
  locale?: Record<string, any>
  canvasStyles?: Record<string, any>
  forwardAmount?: number
  initialOffset: number
  breakLimit?: number
  lib?: TLibProp
  maxRecordDuration: number
  features: {
    lockingEnabled?: boolean
  }
}

interface IEventExtraPropDesc {
  property: string
  title: string
  filter:
    | {
        type: 'range'
        anyLabel: string
        noneLabel: string
        template: string // {{}} - {{}} км/ч
        precision: number
      }
    | {
        type: 'enum'
        anyLabel: string
        noneLabel: string
      }
}

export interface UploadedArchiveFile {
  type: 'File'
  id: string
  title: string
  parentId: string | null
  url: string
  description?: string
  duration?: number
  dateCreated: string | null
  extension?: string | null
  parentFolderId?: string | null
  size: string
  owner: string
  status?: string
  cameraId?: string
  streamUrl?: string
}

export interface CameraArchiveRecord {
  id: string
  type: 'Record'
  archiveId: string
  cameraId: string
  dateCreated: string | number
  description?: string
  duration: number
  from: number
  title: string
  parentFolderId: string | null
  owner: string
  status: string
  streamUrl?: string
  dvrTimelines: ITimeline
}
export interface ArchiveCameraEntity {
  type: 'Camera'
  id: string
}

export type ArchiveEntities = CameraArchiveRecord | UploadedArchiveFile | ArchiveCameraEntity

export interface MountHooks<T> {
  mount(params: T): void
  unmount(): void
}

export interface OverlayParams {
  /**
   * Element that contains overlay.
   */
  overlayContainer: HTMLElement
  /**
   * This element is used to calculate sizes.
   * ResizeObserver tracks changes
   */
  overlayBase: HTMLElement
  /**
   * This sizes are used as a maximum value for coordinates.
   * Also this is used to to place canvas properly,
   * in case when aspect ratio of video or image does not correspond to it's container aspect ratio (black borders).
   */
  naturalHeight: number
  naturalWidth: number
}

type LivePlayerProps = LivePlayerConfig | OldLivePlayerProps
type SavedPlayerProps = SavedPlayerConfig | OldSavedPlayerConfig
type ArchivePlayerProps = ArchivePlayerConfig | OldArchivePlayerConfig

export type PlayerWidgetPropsWithOldConfig =
  | LivePlayerProps
  | SavedPlayerProps
  | ArchivePlayerProps
  | MultiModePlayerConfig
export type PlayerWidgetPropsProvider<T> = T extends PlayerModes.Live
  ? LivePlayerConfig
  : T extends PlayerModes.Saved
  ? SavedPlayerConfig
  : T extends PlayerModes.Archive
  ? ArchivePlayerConfig
  : T extends PlayerModes.MultiMode
  ? MultiModePlayerConfig
  : never

interface WidgetReceivedPropsWithNode<
  P extends {
    [k: string]: any
  },
  A extends string
> extends WidgetReceivedProps<P, A> {
  domElement: HTMLElement
}
export type IWidgetProps = WidgetReceivedPropsWithNode<PlayerWidgetPropsWithOldConfig, ''>
export type IWidgetPropsProvider = WidgetReceivedPropsWithNode<
  LivePlayerConfig | SavedPlayerConfig | ArchivePlayerConfig | MultiModePlayerConfig,
  ''
>

declare global {
  interface Window {
    CAMERA_LOCKING: Record<string, any>
    __NV_CONSTANTS__: {
      maxRecordDuration?: number
    }
  }
}
